import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/navbar_styles.css'; // Ensure the path is correct

function Navbar() {
    return (
        <div className="navbar-container">
            <div className="navbar-logo">Justin Stoner's Portfolio</div>
            <div className="navbar-links">
                <Link to="/">Home</Link>
                <Link to="/algorithms">Algorithms</Link>
                <Link to="/database">Database</Link>
            </div>
        </div>
    );
}

export default Navbar;
