import React from 'react';
import '../../styles/footer_styles.css'; // Ensure the path is correct
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Footer() {
    return (
        <div className="footer-container">
            <div className="footer-links">
                <a href="https://github.com/justinstoner2" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faGithub} /> GitHub
                </a>
                <a href="https://www.linkedin.com/in/justin-stoner-7aa65a100/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} /> LinkedIn
                </a>
                <a href="mailto:justinstoner2@gmail.com">
                    <FontAwesomeIcon icon={faEnvelope} /> Email
                </a>
            </div>
            <div className="footer-copyright">
                © {new Date().getFullYear()} [Your Name]. All rights reserved.
            </div>
        </div>
    );
}

export default Footer;
