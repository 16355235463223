import React from 'react';
import '../../styles/algorithims_styles.css';

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export default class Algorithms extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            array: [],
            screenWidth: window.innerWidth,
            activeIndexes: []
        };
    }
    componentDidMount() {
        window.addEventListener('resize', this.updateScreenWidth);
        this.resetArray();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateScreenWidth);
    }
    updateScreenWidth = () => {
        this.setState({ screenWidth: window.innerWidth });
    }
    mergeSort = async () => {
        // Copy the array from the state to sort it
        let array = [...this.state.array];
        let auxiliaryArray = array.slice();
        await this.mergeSortHelper(array, 0, array.length - 1, auxiliaryArray);
        this.setState({ array });
    }

    mergeSortHelper = async (mainArray, startIdx, endIdx, auxiliaryArray) => {
        if (startIdx === endIdx) return;
        const middleIdx = Math.floor((startIdx + endIdx) / 2);
        await this.mergeSortHelper(auxiliaryArray, startIdx, middleIdx, mainArray);
        await this.mergeSortHelper(auxiliaryArray, middleIdx + 1, endIdx, mainArray);
        await this.doMerge(mainArray, startIdx, middleIdx, endIdx, auxiliaryArray);
    }

    doMerge = async (mainArray, startIdx, middleIdx, endIdx, auxiliaryArray) => {
        let k = startIdx;
        let i = startIdx;
        let j = middleIdx + 1;
        while (i <= middleIdx && j <= endIdx) {
            // To visualize the comparison, you can change color here

            if (auxiliaryArray[i] <= auxiliaryArray[j]) {
                mainArray[k++] = auxiliaryArray[i++];
            } else {
                mainArray[k++] = auxiliaryArray[j++];
            }
            this.setState({ array: mainArray.slice() }); // Update state for re-render
            await sleep(100); // Sleep to slow down the algorithm for visualization
        }
        while (i <= middleIdx) {

            mainArray[k++] = auxiliaryArray[i++];
            this.setState({ array: mainArray.slice() }); // Update state for re-render
            await sleep(100); // Sleep to slow down the algorithm for visualization
        }
        while (j <= endIdx) {

            mainArray[k++] = auxiliaryArray[j++];
            this.setState({ array: mainArray.slice() }); // Update state for re-render
            await sleep(100); // Sleep to slow down the algorithm for visualization
        }
    }


    resetArray(size) {
        const array = [];
        // If size is provided use that, otherwise fallback to 100
        const count = size || 100;
        for (let i = 0; i < count; i++) {
            array.push(randomIntFromInterval(5, 730)); // Adjust the max height if needed
        }
        this.setState({ array });
    }
    sortArray() {
        const array = this.state.array.sort((a, b) => a - b);
        this.setState({ array });
    }
    handleGenerateNewArray = () => {
        const size = document.getElementById('arraySize').value;
        this.resetArray(size ? parseInt(size, 10) : undefined); // Use the input value if provided, otherwise fallback to default
    }

    render() {
        const { array } = this.state;
        const screenWidth = window.innerWidth;
        const barWidth = (screenWidth / array.length) - 2;

        return (
            <>
                <h1 className='centered'>Algorithms</h1>
                <h2 className='centered'>Merge Sort</h2>
                <div className='array-container'>
                    {array.map((value, index) => (
                        <div
                            className={`array-bar ${this.state.activeIndexes.includes(index) ? 'active' : ''}`}
                            key={index}
                            style={{
                                height: `${value}px`,
                                width: `${barWidth}px`
                            }}></div>
                    ))}
                </div >
                <form>
                    <input type="text" id="arraySize" name="arraySize" placeholder="Array Size" />
                </form>
                <button onClick={() => this.resetArray()}>Generate New Array</button>
                <button onClick={() => this.mergeSort()}>Sort Array</button>
            </>
        )
    }
}
function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

