import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import '../../styles/home_styles.css';

function Home() {
    return (
        <div className="home-container">
            <div className="hero-section">
                <img src="./images/1586928225022.jpeg" alt="Justin Stoner" className="profile-pic" />
                <h1>Justin Stoner</h1>
                <p>Production Casting Engineer at Selmet Inc</p>
                <p>Hello! I am currently working as a Development Castings Engineer. I am looking to
                    transition into the wonderful realm of software development. I am a senior at Oregon State University
                    getting my second bachalors degree in computer science. I am currently looking for new job
                    oppertunities where I can be apart of amazing teams that develop state of the art software applications.
                    Please browse my website to learn more about me. Contact me using the links below.</p>
            </div>

            <div className="contact-section">
                <h2>Contact</h2>
                <p>Phone: (541) 401-9520</p>
                <p>Email: justinstoner2@gmail.com</p>
                <p>Address: Creswell OR 97426</p>
                <a href="https://github.com/justinstoner2" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faGithub} /> GitHub
                </a>
                <a href="https://www.linkedin.com/in/justin-stoner-7aa65a100/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} /> LinkedIn
                </a>
                <a href="mailto:justinstoner2@gmail.com">
                    <FontAwesomeIcon icon={faEnvelope} /> Email
                </a>
            </div>

            <div className="education-section">
                <h2>Education</h2>
                <div>
                    <h3>2022 - Current: BS in Computer Science</h3>
                    <p>Oregon State University, Expected Completion: 6/24</p>
                </div>
                <div>
                    <h3>2019-2022: BS in Business Administration</h3>
                    <p>University of Phoenix</p>
                </div>
                <div>
                    <h3>2014-2017: Major in Mechanical Engineering</h3>
                    <p>Utah State University</p>
                </div>
            </div>

            <div className="skills-section">
                <h2>Skills</h2>
                <div class="columns-container">
                    <div class="skills-column">
                        <h3><b>Software Engineering</b></h3>
                        <ul class="bullet-points">
                            <li>Python</li>
                            <li>C#</li>
                            <li>.NET</li>
                            <li>C++</li>
                            <li>Relational database design</li>
                            <li>Web Development</li>
                            <li>React</li>
                        </ul>
                    </div>
                    <div class="skills-column">
                        <h3><b>Manufacturing Engineering</b></h3>
                        <ul class="bullet-points">
                            <li>Six Sigma statistical analysis</li>
                            <li>Lean Methodoligy</li>
                            <li>Wax Press Tool design</li>
                            <li>Casting gating expert</li>
                            <li>GD&T for blue prints</li>
                            <li>SLA Rapid Prototyping</li>
                        </ul>
                    </div>

                    <div class="skills-column">
                        <h3><b>CAD Services</b></h3>
                        <ul class="bullet-points">
                            <li>Solidworks</li>
                            <li>Siemens NX</li>
                            <li>Catia</li>
                            <li>GeoMagic</li>
                            <li>3D Printing</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="experience-section">
                <h2>Experience</h2>
                <div className="job">
                    <div class="job-title">Production Casting Engineer (2021-Current) - </div>
                    <div class="company">Selmet Inc, 33992 7 Mile Ln. Albany, OR 97322</div>
                    <div class="centered-container">
                        <ul class="bullet-points">
                            <li>Produce cost savings projects to reduce manufacturing costs which resulted in $1.3M of savings since starting current role</li>
                            <li>Optimized manufacturing processes by leaning out unnecessary work content</li>
                            <li>Develop new manufacturing techniques to reduce inspection time including inspection templates and custom 3D printed inspection tools</li>
                            <li>Troubleshoot manufacturing issues to salvage potential scrap</li>
                            <li>Liaison between customer and manufacturing for manufacturing specifications regarding deciphering customer blueprints and specifications and apply them in manufacturing techniques</li>
                            <li>Create work instructions</li>
                            <li>Generate AS9102 First Article inspection plans to make process improvements qualifications</li>
                            <li>Negotiate blueprint tolerance relief with customers when appropriate</li>
                        </ul>
                    </div>
                </div>
                <div className='job'>
                    <div class="job-title">Engineer Technician (2017-2021)</div>
                    <div class="company">Selmet Inc, 33992 7 Mile Ln. Albany, OR 97322</div>
                    <div >
                        <ul class="bullet-points">
                            <li>Assisted engineering with optimizing manufacturing processes</li>
                            <li>Generated AS9102 First Article inspection Forms for process improvement qualifications and new part development</li>
                            <li>Create and edit work instructions for manufacturing</li>
                            <li>Operated 3D printer to create templates and custom projects</li>
                            <li>Completed CAD projects requested by engineers</li>
                        </ul>
                    </div>

                </div>
                <div className='job'>
                    <div class="job-title">Parts Mover 2017</div>
                    <div class="company">Selmet Inc, 33992 7 Mile Ln. Albany, OR 97322</div>

                    <ul class="bullet-points">
                        <li>Transferred parts and equipment do different areas around the manufacturing plant</li>
                    </ul>

                </div>
            </div>
        </div>
    );
}

export default Home;
