import logo from './logo.svg';
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/shared/Navbar';
import Footer from './components/shared/Footer';
import Home from './components/pages/Home.js';
import Algorithms from './components/pages/Algorithims';
import Database from './components/pages/Databases.js';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/algorithms" element={<Algorithms />} />
        <Route path="/database" element={<Database />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
