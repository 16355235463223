import React from 'react';

function Database() {
    return (
        <div>
            <h2>Database</h2>
            {/* Your content here */}
        </div>
    );
}

export default Database;
